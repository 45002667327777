import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(JSON.parse(sessionStorage.getItem('userData')).token)
    var token:any = sessionStorage.getItem('demad') //? JSON.parse(sessionStorage.getItem('consignToken')).token : '';
   // console.log(token)
    // request = request.clone(
    //   {headers: request.headers.set('accessToken', 'base64:mcP33ANGzmG5F7LmzUoXICw/Fkje0yCuiGJkw+xCp+Q=')},
    //   {headers: request.headers.set('Authorization','Bearer '+ token )}
    //   );
    if (token) {
      const authReq = request.clone({

        headers: new HttpHeaders({
          // Content-Type:'application/json'
         // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      });
      return next.handle(authReq);
    } 
    else {
      const authReq = request.clone({

        // headers: new HttpHeaders({
        //   'Authorization': 'Bearer ' + '1917|uAL9xYgyzk0xQ6J97QDtMjtpFRwhfoBckUdZquVF'
        // })
      });
      return next.handle(authReq);
    }
  }
}
