import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../common/header/header.component';
import { RouterModule } from '@angular/router';
import { MatSelect, MatSelectModule } from '@angular/material/select';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSelectModule,
    TranslateModule,
  ],
  exports:[HeaderComponent]
})
export class SharedModuleModule { }
