import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/commonService/apiList/api.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  staticPageData: any;
  page: any;

  constructor(public api: ApiService,private route: ActivatedRoute){
    this.page = this.route.snapshot.paramMap.get('page')
    this.staticPage()
  }

staticPage(){
  let data = {
    page: this.page,
  }
  this.api.staticPage(data).subscribe((res:any)=>{
    if (res.success == true) {
      this.staticPageData = res.data.page_content

    }
  })
}

}
