import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './commonService/Notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'demand';
  constructor(private translateService: TranslateService,
     @Inject(DOCUMENT) private document: Document,
     private notificationService:NotificationService
     
     ){
    this.translateService.setDefaultLang(sessionStorage.getItem('selectLanguage') || '');
    if (sessionStorage.getItem('selectLanguage') == 'ar') {
      this.document.body.classList.add('order_warp');
    } else {
      this.document.body.classList.remove('order_warp');
    }
    this.notificationService.listen()

  }


  
}
