import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/commonService/apiList/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  selected: any = 'en'
  categoryList: any=[]
  constructor(private translateService: TranslateService, @Inject(DOCUMENT) private document: Document, private api:ApiService) {

    if (sessionStorage.getItem('selectLanguage')) {
      sessionStorage.setItem('selectLanguage', sessionStorage.getItem('selectLanguage') || '');
      this.translateService.setDefaultLang(sessionStorage.getItem('selectLanguage') || '');
      this.selected = sessionStorage.getItem('selectLanguage')
      if (this.selected == 'ar') {
        this.document.body.classList.add('order_warp');
      } else {
        this.document.body.classList.remove('order_warp');
      }
    }
    else {
      sessionStorage.setItem('selectLanguage', 'en');

    }
  }

  ngOnInit(){
    this.getCategory()
  }

  switchLanguage(language: any) {
    this.translateService.use(language);
    sessionStorage.setItem('selectLanguage', language);
    if (language == 'ar') {
      this.document.body.classList.add('order_warp');
    } else {
      this.document.body.classList.remove('order_warp');
    }
  }

  getCategory(){
    this.api.getCategoriesApi().subscribe((res:any)=>{
      if(res.success == true){
        this.categoryList = res.data
      }
    })
  }
}
