<app-header></app-header>
<section class="privacy_poilce">
    <div class="container">
            <div class="row pt-4">   
              <div  [innerHTML]="staticPageData" style="width: 90%; margin: auto;"></div>
                <!-- <h3>Privacy Policy</h3>
                <p>Welcome to Wathabe platform. We are committed to protecting your privacy and the security of your personal information. This Privacy Policy explains how we collect, use, and safeguard your data. By accessing or using our services, you consent to the practices described in this policy.</p>
                <h5 class="pb-3">Information We Collect</h5>
                <h5>User Information</h5>
                <ul class="ms-4">
                    <li>When you create an account, we collect personal information such as your name, email address, and contact details.</li>
                    <li>Freelancers may provide additional information in their profiles, such as skills, work history, and portfolio.</li>
                    <li>Clients may share information about their projects and payment preferences.</li>
                </ul>
                <h5>Transaction Data</h5>
                <ul class="ms-4">
                    <li>To facilitate payments and transactions, we collect data related to your financial transactions, including payment methods and billing details.</li>
                    <li>We may store communications between users for dispute resolution and customer support.</li>
                </ul>
                <h5>Usage Information</h5>
                <ul class="ms-4">
                 <li>We collect data about how you interact with our platform, including your IP address, browser type, device information, and pages visited.</li>
                 <li>We use cookies and similar technologies to enhance your experience and personalize content and ads.</li>
                </ul>
                <h5>How We Use Your Information</h5>
                <ul class="ms-4">
                    <li>To provide and maintain our services, including facilitating connections between Clients and Freelancers.</li>
                    <li>To communicate with you about your account, transactions, and platform updates.</li>
                    <li>To improve our services, analyze usage patterns, and enhance the user experience.</li>
                    <li>To enforce our Terms and Conditions and ensure a secure and trusted environment.</li>
                    <li>To send marketing communications, promotions, and relevant content (you can opt out at any time).</li>
                   </ul>
                   <h5>Data Security</h5>
                   <p>
                    We employ industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, or destruction. However, no method of data transmission or storage is entirely secure, and we cannot guarantee absolute security.
                   </p>
                   <h5>Sharing Your Information</h5>
                   <ul class="ms-4">
                    <li>We may share your data with third-party service providers, such as payment processors and customer support tools, to facilitate our services.</li>
                    <li>Your profile information may be visible to other users, subject to your privacy settings.</li>
                    <li>We may disclose information in response to legal requests, to protect our rights, or to prevent fraud or harm.</li>
                   </ul>
                   <h5>Your Choices</h5>
                   <ul class="ms-4">
                    <li>You can review and update your account information through your profile settings.</li>
                    <li>You may choose to delete your account, subject to our retention policies.</li>
                    <li>You can manage your communication preferences and opt out of marketing emails.</li>
                    <li>Browser cookies can be managed or disabled in your browser settings</li>
                   </ul>
                   <h5>Updates to this Privacy Policy</h5>
                   <p>We may update this Privacy Policy to reflect changes in our practices or legal requirements. We will notify you of significant changes through the website or email.</p>
                   <h5>Contact Us</h5>
                   <p class="pb-4">If you have any questions or concerns about this Privacy Policy or our data practices, please <a href="javascript:;">contact us.</a></p> -->
            </div>
    </div>
</section>
<footer>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 pt-2">
                  <img src="assets/images/logo_demand.png" alt=""/>
            </div>
            <div class="col-lg-3">
                  <h5>Explore</h5>
                  <ul>
                    <li><a href="javascript:;">About us</a></li>
                    <li><a href="javascript:;">Careers</a></li>
                    <li><a href="javascript:;">Testimonials</a></li>
                    <li><a href="javascript:;">Blog</a></li>
                    <li><a href="javascript:;">Values</a></li>
                  </ul>
            </div>
            <div class="col-lg-3">
              <h5>Useful Links</h5>
                  <ul>
                    <li><a href="javascript:;">Community Guideline</a></li>
                    <li><a href="javascript:;">Terms & Conditions </a></li>
                    <li><a href="javascript:;">Privacy Policy</a></li>
                  </ul>
            </div>
            <div class="col-lg-3">
              <h5>Contact Us</h5>
              <p>Phone No : <span class="text-white">123-456-7890</span></p>
              <p>Email : <span class="text-white">contact@demand.com</span></p>
              <div class="social-link pt-2">
                  <a href="javascript:;">
                  <svg width="12" height="23" viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.51798 22.3087V12.1492H10.9454L11.4549 8.17142H7.51798V5.63774C7.51798 4.4899 7.83779 3.70402 9.48518 3.70402H11.5726V0.157611C10.5574 0.0485291 9.536 -0.00353282 8.51459 0.000185889C5.48509 0.000185889 3.40509 1.84962 3.40509 5.2448V8.16398H0V12.1418H3.41253V22.3087H7.51798Z" fill="white"/>
                  </svg>
                  </a>
              
                  <a href="javascript:;">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7076 0.000488281H6.55145C3.48379 0.000488281 0.973389 2.50978 0.973389 5.57855V16.7347C0.973389 19.8023 3.48379 22.3127 6.55145 22.3127H17.7076C20.7752 22.3127 23.2856 19.8023 23.2856 16.7347V5.57855C23.2856 2.50978 20.7752 0.000488281 17.7076 0.000488281ZM12.1295 15.805C9.56192 15.805 7.48102 13.7233 7.48102 11.1568C7.48102 8.58923 9.56192 6.50834 12.1295 6.50834C14.696 6.50834 16.778 8.58923 16.778 11.1568C16.778 13.7233 14.696 15.805 12.1295 15.805ZM16.7779 5.11382C16.7779 5.88387 17.4016 6.50834 18.1725 6.50834C18.9433 6.50834 19.567 5.88387 19.567 5.11382C19.567 4.34377 18.9433 3.71931 18.1725 3.71931C17.4016 3.71931 16.7779 4.34377 16.7779 5.11382Z" fill="white"/>
                      </svg>
                  </a>
              
                  <a href="javascript:;">
                    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.3717 5.19467C22.3878 5.4116 22.3878 5.62728 22.3878 5.84297C22.3878 12.4437 17.3639 20.0497 8.18237 20.0497C5.35368 20.0497 2.72579 19.2303 0.514404 17.8073C0.916024 17.8531 1.30277 17.8693 1.7205 17.8693C4.05461 17.8693 6.20278 17.0809 7.91959 15.736C5.72431 15.6901 3.88479 14.2522 3.25013 12.2738C3.55878 12.3197 3.86868 12.3507 4.19344 12.3507C4.64093 12.3507 5.09089 12.2887 5.50862 12.1809C3.21914 11.7173 1.50482 9.70794 1.50482 7.28087V7.21889C2.17047 7.58952 2.94272 7.82132 3.76083 7.85231C2.4159 6.95734 1.53457 5.42647 1.53457 3.6948C1.53457 2.7676 1.78124 1.91726 2.21385 1.17599C4.67192 4.20426 8.36707 6.18385 12.5097 6.40078C12.4329 6.0289 12.3857 5.6434 12.3857 5.25665C12.3857 2.50481 14.612 0.263672 17.3787 0.263672C18.8166 0.263672 20.1145 0.866102 21.0268 1.8404C22.1548 1.62348 23.2357 1.20574 24.1951 0.634303C23.8245 1.7933 23.0349 2.7676 21.9998 3.38614C23.0051 3.27706 23.9794 2.9994 24.8744 2.61265C24.1951 3.60183 23.346 4.48316 22.3717 5.19467Z" fill="white"/>
                      </svg>
                  </a>
             
                  <a href="javascript:;">
                    <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0457 1.14669C26.2378 1.30201 27.3772 2.46139 27.5222 3.67047C28.0354 8.10158 28.0354 12.3984 27.5222 16.8281C27.3772 18.0372 26.2378 19.198 25.0457 19.3519C17.7764 20.2552 10.4249 20.2552 3.15763 19.3519C1.96442 19.1976 0.825066 18.0372 0.680101 16.8281C0.166854 12.3981 0.166854 8.10158 0.680101 3.67047C0.825066 2.46139 1.96442 1.30167 3.15763 1.14669C10.4249 0.24342 17.776 0.24342 25.0457 1.14669ZM11.8 5.64791V14.8522L18.7031 10.2502L11.8 5.64791Z" fill="white"/>
                      </svg>
                  </a>
                </div>
            </div>
        </div>
        <hr class="mt-4 bor">
        <div class="copyright text-center pt-2">©2023 Demand | All Rights Reserved</div>
    </div>
</footer>