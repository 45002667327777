import { Injectable } from '@angular/core';
// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/messaging";

import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class NotificationService {
    deviceToken: any;
    fcmToken: any;
    message: any;
    private eventSource = new BehaviorSubject<string>("");
    eventSubject = this.eventSource.asObservable();
  
    // constructor(public angularFireMessaging: AngularFireMessaging) {
    //     try {
    //         const messaging = firebase.messaging();
    //     } catch (e) {
    //       
    //     }
    // }

    requestPermission() {
        
        const messaging = getMessaging();
         return  getToken(messaging)
      }
      
      listen() {
        const messaging = getMessaging();
        onMessage(messaging, (payload:any) => {
          console.log('Message received. ', payload);
          this.message=payload;
          const notification = new Notification('New Message', {
            body: this.message.notification.body,
          });

        });
      }

      updateNotificationStatus(){

      }

      deleteFirebaseToken(){
        const messaging = getMessaging();
        getToken(messaging).then((res:any)=>{
            deleteToken(messaging)
        })
      }




    // requestPermission() {
    //     this.angularFireMessaging.requestToken.subscribe((token:any) => {
    //        
    //         if (token == null) {
      
    //         } else {
    //             this.fcmToken = token;
    //            
    //             localStorage.setItem('fcmToken',this.fcmToken )
    //             this.receiveNotificationMessage();
    //         }
    //     }, (err:any) => {
    //         console.error('Unable to get permission to notify.', err);
    //     });
    // }

    // receiveNotificationMessage() {
    //    
    //     this.angularFireMessaging.messages.subscribe(
    //         (payload: any) => {
    //            
    //         
    //             if(payload.notification != undefined){
    //                 this.showNotification(payload.notification);
    //             }
                
    //         }, (err:any) => {
    //    
    //         })
    // }

    showNotification(value:any) {
        // create a new notification
        const notification = new Notification(value.title, {
            body: value.title,
            icon: './assets/images/main-logo.png'
        });

        // close the notification after 10 seconds
        setTimeout(() => {
            notification.close();
        }, 10 * 1000);

        // navigate to a URL
        notification.addEventListener('click', () => {
            window.open('https://www.javascripttutorial.net/web-apis/javascript-notification/', '_blank');
        });
    }

    async checkPermission() {
        // check notification permission
        let granted = false;
        if (Notification.permission === 'granted') {
            granted = true;
        } else if (Notification.permission !== 'denied') {
            let permission = await Notification.requestPermission();
            granted = permission === 'granted' ? true : false;
        }

    
    }

    emitEvent(value: string) {
        this.eventSource.next(value)
      }

 
}