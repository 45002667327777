import { Pipe, PipeTransform } from '@angular/core';

interface Intervals {
  [key: string]: number; // Index signature allowing string indexing
}

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date): string {
    if (!value) return '';

    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

    const intervals: Intervals = {
      day: 86400, // 24 hours in seconds
      hour: 3600,
      minute: 60,
    };

    let counter;
    let result = '';
    for (const interval in intervals) {
      if (Object.prototype.hasOwnProperty.call(intervals, interval)) {
        counter = Math.floor(seconds / intervals[interval]);
        if (counter > 0) {
          if (counter === 1) {
            result = `${counter} ${interval} ago`;
          } else {
            result = `${counter} ${interval}s ago`;
          }
          break; 
        }
      }
    }

    if (!result) {
      result = 'Just now'; 
    }

    return result;
  }
}
