import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './commonService/guards/authguard.guard';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./component/landing/landing.module').then(m => m.LandingModule) },
  { path: 'login', loadChildren: () => import('./component/login/login.module').then(m => m.LoginModule) },
  { path: 'signup', loadChildren: () => import('./component/signup/signup.module').then(m => m.SignupModule) },
  { path :'forgot' , loadChildren: () => import('./component/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule) },
  { path: 'home/:userInfo', loadChildren:() => import('./component/home/home.module').then(m => m.HomeModule)},
  { path: 'freelencer', loadChildren:() => import('./component/freelencer/freelencer-home/freelencer-home.module').then(m => m.FreelencerHomeModule)},
  { path: 'createProfile', loadChildren:() => import('./component/create-profile/create-profile.module').then(m => m.CreateProfileModule)},
  // { path: 'allJobs', loadChildren:() => import('./component/client/all-jobs/all-jobs.module').then(m => m.AllJobsModule)},
  { path: 'dashboard', loadChildren: () => import('./component/freelancer/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'client', loadChildren: () => import('./component/client/dashboard-client/dashboard-client.module').then(m => m.DashboardClientModule) },
  {path:'staticPage/:page', component:PrivacyPolicyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
