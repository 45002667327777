<nav class="navbar navbar-expand-lg navbar-light gap-3 py-3">
  <div class="container">
    <!-- logo-->
    <!-- <a class="navbar-brand logo-english" href="#"><img src="assets/images/wathabe_logo.png" alt=""/></a> -->
    <a class="navbar-brand logo-arable" href="#"><img src="assets/images/logo-arabia-white.png" alt="" /></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
      aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- nav bar-->
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
        <li class="nav-item">
          <a class="nav-link active" href="#">{{"Home" | translate}}</a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link" href="#">{{"How It works" | translate}}</a>
          </li> -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="dropdownMenuLink" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{"Browse Jobs" | translate}}
          </a>
          <!-- <div class="dropdown-menu menu_block" aria-labelledby="dropdownMenuButton1">
              <div class="row">
                  <div class="col-lg-4">
                    <ul>
                 
                      <li><a class="dropdown-item" href="#">Branding </a></li>
                      <li><a class="dropdown-item" href="#">Website Design</a></li>
                      <li><a class="dropdown-item" href="#">UI/UX Software Design</a></li>
                      <li><a class="dropdown-item" href="#">Graphic Design</a></li>
                    </ul>
                  </div>
                  <div class="col-lg-4">
                    <ul>
                 
                      <li><a class="dropdown-item" href="#">IoT Development </a></li>
                      <li><a class="dropdown-item" href="#">Quality Assurance and Testing</a></li>
                      <li><a class="dropdown-item" href="#">E-commerce Development</a></li>
                      <li><a class="dropdown-item" href="#">Custom Software Development</a></li>
                    </ul>
                  </div>
                  <div class="col-lg-4">
                    <ul>
            
                      <li><a class="dropdown-item" href="#">DevOps Services </a></li>
                      <li><a class="dropdown-item" href="#">Web Development</a></li>
                      <li><a class="dropdown-item" href="#">Cloud Application Development</a></li>
                      <li><a class="dropdown-item" href="#">Mobile App Development</a></li>
                    </ul>
                  </div>
             
            </div> -->
          <div class="dropdown-menu menu_block" aria-labelledby="dropdownMenuButton1">
            <div class="row">
              <div class="col-lg-12">
                <!-- <h4 class="textend">Design</h4> -->
                <ul class="browse-job-header">
                  <!-- <li><a class="dropdown-item"  [routerLink]="['/browse-jobs']"> </a></li> -->
                  <!-- <li><a class="dropdown-item" href="#">Website Design</a></li> -->
                  <!-- <li><a class="dropdown-item" href="#">UI/UX Software Design</a></li> -->
                  <li *ngFor="let data of categoryList">{{data.category_name}}</li>
                </ul>
              </div>
            </div>
          </div>

        </li>
        <li class="nav-item">
          <a class="nav-link"  [routerLink]="['/browse-freelancer']">{{"Browse Freelancers" | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact">{{"Contact" | translate}}</a>
        </li>
      </ul>
      <div class="ps-0 ps-lg-0 d-flex align-items-center">
        <button type="button" class="btn text-white font18 login_order direction" [routerLink]="['/login']">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.1738 21C20.1738 19.6044 20.1738 18.9067 20.0016 18.3389C19.6138 17.0605 18.6134 16.06 17.335 15.6722C16.7672 15.5 16.0694 15.5 14.6738 15.5H9.67383C8.27827 15.5 7.58048 15.5 7.01269 15.6722C5.73428 16.06 4.73387 17.0605 4.34607 18.3389C4.17383 18.9067 4.17383 19.6044 4.17383 21M16.6738 7.5C16.6738 9.98528 14.6591 12 12.1738 12C9.68855 12 7.67383 9.98528 7.67383 7.5C7.67383 5.01472 9.68855 3 12.1738 3C14.6591 3 16.6738 5.01472 16.6738 7.5Z"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          {{"Login" | translate}}
        </button>
        <button type="button" class="btn btn_black signup_order" [routerLink]="['/signup']">{{"Signup" |
          translate}}</button>

        <div class="select_block">

          <mat-select [(value)]="selected" class="form-control select_language" id="exampleFormControlSelect1"
            (selectionChange)="switchLanguage($event.value)">
            <mat-option value="en">
              English
            </mat-option>
            <mat-option value="ar">
              Arabic
            </mat-option>
          </mat-select>
        </div>
      </div>

    </div>
  </div>
</nav>